import React, { useState, useEffect, useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import slug from "slug"
import throttle from "lodash.throttle"

/* Import Global Contexts */
import HoverContext from "~context/hover"

// Import Local Asset(s)
import "./header.css"

export default ({ location }) => {
  const [, setIsHovered] = useContext(HoverContext)

  // saves last location when visited from /info
  // if found and on /info, is used to return to previous page
  const { state = {} } = location

  const lastLocation = state ? state.lastLocation : null

  const {
    allSanityProject: { edges: projects },
  } = useStaticQuery(graphql`
    query HeaderQuery {
      allSanityProject {
        edges {
          node {
            _key
            _id
            client
          }
        }
      }
    }
  `)

  const [isScrolled, setIsScrolled] = useState(false)
  const [lastScrollPosition, setLastScrollPosition] = useState(0)
  const isProject = location.pathname.includes("/project")
  const isInfo = location.pathname.includes("/info")

  const handleScroll = event => {
    let scrollTop = window.scrollY
    // show if at top of page
    if (scrollTop === 0) {
      setIsScrolled(false)
      setLastScrollPosition(scrollTop)
      return
    }
    if (scrollTop > lastScrollPosition) {
      setIsScrolled(true)
      setLastScrollPosition(scrollTop)
    } else {
      setIsScrolled(false)
      setLastScrollPosition(scrollTop)
    }
  }

  const throttledHandleScroll = throttle(handleScroll, 200)

  useEffect(() => {
    window.addEventListener("scroll", throttledHandleScroll, false)
    return () => {
      window.removeEventListener("scroll", throttledHandleScroll, false)
    }
  }, [throttledHandleScroll])

  const handleMouseEnter = id => {
    if (!id) return
    setIsHovered(id)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <header
      className={`${isProject ? "isProject" : ""} ${isInfo ? "isInfo" : ""}`}
    >
      <nav>
        <div
          className={`title ${isInfo ? "active" : null} ${
            isScrolled ? "isScrolled" : ""
          }`}
        >
          <Link to="/">
            <span id="hannah">Hannah</span>
            <span>Kinlaw</span>
          </Link>
          <Link
            to={isInfo && lastLocation ? lastLocation.pathname : "/info"}
            state={{ lastLocation: location }}
          >
            {location.pathname === "/info" ? "Back" : "Info"}
          </Link>
        </div>
        <div className="projects">
          {projects.map(project => (
            <Link
              key={project.node._id}
              to={`/project/${slug(project.node.client, { lower: true })}`}
              activeClassName="active"
            >
              <p
                className="client"
                onMouseEnter={() => {
                  handleMouseEnter(project.node._id)
                }}
                onMouseLeave={handleMouseLeave}
              >
                {project.node.client}
              </p>
            </Link>
          ))}
        </div>
      </nav>
    </header>
  )
}
