import React from "react"

/* Import Global Font(s) */
import "~assets/fonts/PyteLegacyLibrary-Vulture.css"
import "~assets/fonts/Century.css"

/* Import Global Normalize*/
import "normalize.css"

/* Import Global Reset */
import "./reset.css"

/* Import Global Styles */
import "./global.css"

/* Import Global Providers */
import HoverProvider from "~providers/hover"

/* Import Global Components */
import Loader from "~components/loader/loader"
import Header from "~components/header/header"
import TransitionRouter from "~components/transitionRouter/transitionRouter"

/* Import Local Styles */
import styles from "./index.module.css"

const Layout = ({ location, children }) => (
  <HoverProvider>
    <Loader />
    <Header location={location} />
    <main className={styles.main}>
      <TransitionRouter location={location}>{children}</TransitionRouter>
    </main>
  </HoverProvider>
)

export default Layout
