import React, { useState, useEffect } from "react"
import classnames from "classnames"

// Import Local Style(s)
import styles from "./loader.module.css"

export default () => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (!isLoaded) {
      if (window) {
        window.setTimeout(() => {
          setIsLoaded(true)
        }, 100)
      }
    }
  }, [isLoaded, setIsLoaded])

  return (
    <div
      className={classnames(styles.loadingOverlay, {
        [styles.loaded]: isLoaded,
      })}
    />
  )
}
