import React from "react"
import classnames from "classnames"
import { TransitionGroup, CSSTransition } from "react-transition-group"

import "./transitions.css"

import styles from "./transition-router.module.css"

const TransitionRouter = ({ location, children }) => (
  <TransitionGroup className="transition-group">
    <CSSTransition key={location.key} classNames="fade" timeout={700}>
      {/* the only difference between a router animation and
                any other animation is that you have to pass the
                location to the router so the old screen renders
                the "old location" */}
      <div className={classnames("page", styles.page)}>{children}</div>
    </CSSTransition>
  </TransitionGroup>
)

export default TransitionRouter
