import React, { useState } from "react"

// Import Global Context(s)
import HoverContext from "~context/hover"

const HoverProvider = ({ children }) => {
  const [isHovered, setIsHovered] = useState()

  return (
    <HoverContext.Provider value={[isHovered, setIsHovered]}>
      {children}
    </HoverContext.Provider>
  )
}

export default HoverProvider
